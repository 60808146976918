<template>
    <div>
        <div class="default_box">
            <div class="default_header">
                <h1>Правила использования материалов размещаемых в разделе "Новости"</h1>
            </div>
            <div class="default_box_content bg_white p15">
                <div class="text-left">
                    <b>Использование текстовых материалов</b><br>
                    Текстовые материалы допускается свободно использовать только в объёме не превышающим 400 символов. При этом обязательно указывается читаемая ссылка и гиперссылка не закрытая от индексации поисковыми системами ведущая непосредственно на цитируемый материал.<br><br>
                    <b>Использование фото и видео материалов</b><br>
                    Данный раздел описывает правила использования материалов для сайтов, за исключением страниц в социальных сетях, блоггерских сервисах и группах мессенджеров.<br>
                    Некоммерческие сайты могут свободно использовать первую из опубликованных в новости фотографий и только совместно с сопровождающим её текстом в полном объёме и с индексируемой гиперссылкой на соответствующую новость на https://okaygorod.com/. Если хотя бы одно из условий не выполняется — использование материалов не разрешается
                    Официальные сайты органов власти могут свободно использовать не более трёх фотографий но только для иллюстрирвания текста новости описывающего изображенное на них событие.<br>
                    Коммерческие сайты, то есть сайты получающие доход от размещения рекламных материалов, могут использовать фотоматериалы только при получении письменного согласия.<br>
                    Использование фотоматериалов допускается только в оригинальном виде, какое-либо их редактирование не допускается.<br>
                    Видеоматериалы можно свободно распространять только в виде ссылки на оригинальное видео на канале youtube или встроенного в страницу сайта оригинального видео с нашего канала youtube. Публикация копий видео или его фрагментов — не допускается.<br><br>
                    Единственным лицом, уполномоченным давать разрешение на использование материалов, является Главный редактор.
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "Regulation",
    components: {},
    metaInfo() {
        return {
            title: `Правила использования материалов `,
            meta: [
                { name: 'description', itemprop: 'description', content: 'Правила использования материалов портала Окей Город' },
                { property: 'og:url', content: this.$config.url + this.$route.fullPath },
                { property: 'og:title', content: `Правила использования материалов портала Окей Город ${this.$myCity.name}` },
                { property: 'og:description', content: 'Правила использования материалов портала Окей Город' },
                { property: 'og:image', content: 'https://okaygorod.com/images/logoHD.png' },
                { property: 'og:type', content: 'website' }
            ]
        }
    },
    mounted() {
        this.$scrollToTop();
    }
}
</script>
<style scoped>
</style>